export const environment = {
  production: false,

  clientName: 'CONDINA - AGP',
  apiGatewayFront: 'https://condina-proveedor.proyectosurbanos.com.co/',
  apiGatewayBackOffice: 'https://condina-proveedor.proyectosurbanos.com.co/',
  apiUrl: 'https://condina-proveedor.proyectosurbanos.com.co/',
  urlPath: 'https://condina-proveedor.proyectosurbanos.com.co/',
  customCss: false,
  // Whether or not to enable debug mode
  // Setting this to false will disable console output
  enableDebug: false,
  // siteKeyCaptcha: '6LdLtBsgAAAAAJksLT4W-TI-oRed7Uk9B8Y-EECB', DEMO
  // siteKeyCaptcha: '6LfL5fkfAAAAALmrMTjEg18wnQm5uR3ZNbBy-pL7' ZX

  siteKeyCaptcha: '6Ldyb_EoAAAAAPCMMhL59_gMaJ6oxspG6B72mDlb',

  googleAnalyticsCode: 'UA-140785247-1',
};